import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

const uiSlice = createSlice({
  name: 'ui states',
  initialState,
  reducers: {
    sidebarToggle(state) {
      state.sidebarShow = !state.sidebarShow
    },
    setSidebarUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
  },
})

export const uiActions = uiSlice.actions
export default uiSlice.reducer
