import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import uiReducer from './ui-states'

const store = configureStore({
  reducer: {
    user: userReducer,
    uiStates: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
