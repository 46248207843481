import { createSlice } from '@reduxjs/toolkit'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
  details: user ? user : '',
  auth_token: user ? user.auth_token : '',
  isLoggedIn: user ? true : false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDetails(state, action) {
      state.details = action.payload
    },
    setAuth_Token(state, action) {
      state.auth_token = action.payload
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
  },
})

export const userActions = userSlice.actions
export default userSlice.reducer
